import React, { useEffect, Fragment } from "react"
import BelowFoldCards from "../../general/belowFoldCards"
import CTA from "../../general/cta"
import EntryFold from "../../general/entryFold"
import Card from "../../../../../assets/img/card-icon.inline.svg"
import WithdrawIcon from "../../../../../assets/img/withdrawIcon.inline.svg"
import Zero from "../../../../../assets/img/zero-card-icon.inline.svg"
import ImageTextLeft from "../../general/imageTextLeft"
import ImageTextRight from "../../general/imageTextRight"
import BlockCardIllustration from "../../../../../assets/img/blockIllustration.inline.svg"
import CashPayIllustration from "../../../../../assets/img/cashPayIllustration.inline.svg"
import OrderCardIllustration from "../../../../../assets/img/orderCard.inline.svg"
import { scrollToElement } from "../../../../utility/utils"
import { StaticImage } from "gatsby-plugin-image"
import MoreForYouComponent from "../../general/more-for-you"

import TransferIcon from '../../../../../assets/img/more-for-you/kuda-transfer.inline.svg'
import KudaGlobeIcon from '../../../../../assets/img/more-for-you/kuda-globe-icon.inline.svg'
import BorrowIcon from '../../../../../assets/img/more-for-you/kuda-borrow-icon.inline.svg'

const entryContent = {
  title: "Get a Kuda card, no maintenance fee.",
  subtitle:
    "Request a Kuda card on the Kuda app. We'll deliver it to you or you can pick it up whenever you're ready.",
  // illustration: CardNewIllustration,
  illustration: <StaticImage src={`../../../../../assets/img/kuda-new-cards.png`} placeholder="blurred" alt="kuda Microfinance Bank Savings, Spend, Budgeting." loading="eager"/>

}

const kudaTopFeatures = [
  {
    icon: <Card />,
    text: "Pick up your card from us or choose our easy delivery option",
  },
  {
    icon: <WithdrawIcon />,
    text: "Our cards are accepted on Spotify, Netflix, Prime Video, and Showmax!",
  },
  {
    icon: <Zero />,
    text: "You’ll never pay a card maintenance fee again!",
  },
]

const payNothing = {
  payTitle: "Request your card on the Kuda app.",
  subtitle:
    "You can pick up your card from us or we'll deliver it to you, no maintenance fee included!",
  url: "https://help.kuda.com/support/solutions/articles/73000560733-request-a-kuda-visa-card",
  name: "Learn How To Request A Card",
  payIllustration: <OrderCardIllustration />,
}

const debit = {
  payTitle: "Need to pay with cash? Use your Kuda card at ATMs nationwide.",
  subtitle:
    "The Kuda card is accepted at ATMs across Nigeria.",
  url: "https://help.kuda.com/support/solutions/articles/73000560730-kuda-visa-card-faqs",
  name: "Learn More About Cards",
  payIllustration: <CashPayIllustration />,
}

const payBills = {
  payTitle: "Turn off access, turn on safety.",
  subtitle:
    "Life happens. Milk spills. Debit cards go missing. If that ever happens, you can block your missing card on the app so no one can use it. We’d like to see them try.",
  url: "https://help.kuda.com/support/solutions/articles/73000560734-block-your-kuda-card",
  name: "Learn How To Block Your Card",
  payIllustration: <BlockCardIllustration />,
}

const moreForYou = [
  {
      icon: <TransferIcon />,
      title: "Transfer & Spend",
      subText: "Send money for free to any Nigerian account with 25 free transfers every month.",
      linkTo: `/en-ng/spend/`
    },
    {
      icon: <KudaGlobeIcon />,
      title: "Cardless Payment",
      subText: "Use Pay ID, Pay With USSD, or free transfers to make quick and safe payments without a debit card.",
      linkTo: `/en-ng/cardless-payments/`
    },
  {
    icon: <BorrowIcon />,
    title: "Loans",
    subText: `Get instant loans up to ₦150,000 in the Kuda loan app easily in Nigeria without paperwork.`,
    linkTo: `/en-ng/personal-loan/`
  },

]

const Cards = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (
    <Fragment>
      <EntryFold
        ctaClass={"desktop-only"} 
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        illustration={entryContent.illustration}
      />
      <BelowFoldCards topFeatures={kudaTopFeatures} />
      <ImageTextRight
        title={payNothing.payTitle}
        subtitle={payNothing.subtitle}
        illustration={payNothing.payIllustration}
        name={payNothing.name}
        url={payNothing.url}
        isExternal={true}
      />
      <ImageTextLeft
        title={debit.payTitle}
        subtitle={debit.subtitle}
        illustration={debit.payIllustration}
        name={debit.name}
        url={debit.url}
        isExternal={true}
      />
      <ImageTextRight
        title={payBills.payTitle}
        subtitle={payBills.subtitle}
        illustration={payBills.payIllustration}
        name={payBills.name}
        url={payBills.url}
        isExternal={true}
      />
      <MoreForYouComponent moreForYou={moreForYou} />
      <CTA />
    </Fragment>
  )
}

export default Cards
